import React from "react";

function Work() {
  return (
    <div className="pt-16 px-4 sm:px-6 lg:px-8 lg:pt-24 text-center">
      <h1 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
        Experience Overview
      </h1>
      <p className="text-lg mt-4 leading-6 text-gray-500">
        {/* A little about me */}
      </p>
      <ol className="mt-6 space-y-4">
        <li className="text-2xl text-white font-semibold text-center mb-5">
          Education
          {/* Purdue University */}
          <p className="text-lg leading-6 text-gray-500">
            <strong>Purdue University</strong>
            <br />
            Major: Computer Engineering, Minor: Economics
            <br />
            Expected Graduation: May 2028
          </p>
          {/* Council Rock South */}
          {/* <p className="text-lg leading-6 text-gray-500">
    <strong>Council Rock South</strong>
    <br />
    Graduated: June 2024
    <br />
    Top 10% of Class
  </p> */}
        </li>

        <ul className="space-y-10">
          {/* Work Experience */}
          <li className="text-2xl text-white font-semibold text-center mb-5">
            Work Experience
            {/* Action Manufacturing Company */}
            <p className="text-lg leading-6 text-gray-500 ">
              <strong>Action Manufacturing Company</strong> - Built and tested
              armament equipment for M107 Warheads to ensure deployment
              reliability. Organized decade-old schematics into a virtual
              catalog, improving accessibility and retrieval time. Developed a
              quality control inspection system for MK60 gear systems through
              systematic testing.
            </p>
          </li>
          {/* Research Experience */}
          <li className="text-2xl text-white font-semibold text-center mb-5">
            Research Experience
            {/* First Time Researcher Fellowship */}
            <p className="text-lg leading-6 text-gray-500">
              <strong>First Time Researcher Fellowship (FTR)</strong> -
              Developing radiation testing procedures for commercial
              microelectronics to enhance satellite reliability. Engineering
              real-time monitoring systems using Python and Raspberry Pi for
              efficient data acquisition.
            </p>
            {/* Abington Neurological Associates */}
            <p className="text-lg leading-6 text-gray-500">
              <strong>Abington Neurological Associates</strong> - Reviewed 400+
              patient records to identify early warning signs for migraine
              prediction research. Managed patient facilities, ensuring
              equipment maintenance and high standards of care.
            </p>
          </li>

          <li className="text-2xl text-white font-semibold text-center mb-5">
            Projects & Awards
            <p className="text-lg leading-6 text-gray-500">
              <strong>SmithAI Project (2025)</strong>, Built an AI platform in
              36 hours that deploys AI-powered agents with HTML, JavaScript,
              Tailwind, Flask, CSS, AWS, and MongoDB on the back end. Recognized
              with awards including Best Use of Generative AI (MLH) and Best
              Cloud Implementation (Caterpillar), and most likely to be business
              (Purdue Innovates).
            </p>
            {/* Outstanding Presentation Award */}
            <p className="text-lg leading-6 text-gray-500">
              <strong>Outstanding Presentation Award (2024)</strong>, Pedestrian
              Safety Design Project: Developed a universal bike light clip to
              enhance visibility and safety for pedestrians and drivers at a key
              campus intersection in ENGR131 class.
            </p>
            {/* Final Design Review */}
            <p className="text-lg leading-6 text-gray-500">
              <strong>Final Design Review (2023)</strong>: One of 318 students
              chosen to present a prototype of an insect nanolab at NASA's Final
              Design Review in Houston, showcasing an innovative solution for
              sustainable food sources aboard the International Space Station
              (ISS).
            </p>
            {/* Widener Leadership Awards */}
            <p className="text-lg leading-6 text-gray-500">
              <strong>Widener Leadership Awards (2023)</strong>: One of 129
              students selected across Pennsylvania, New Jersey, and Delaware
              for exceptional leadership in school and community activities,
              recognized by Widener University in partnership with NBC10.
            </p>
            {/* Jewish Exponent Article */}
            <p className="text-lg leading-6 text-gray-500">
              <strong>Jewish Exponent Article (2023)</strong>: Interviewed for &
              quoted in the Jewish Exponent for an article on the revival of
              Sigma Alpha Rho.
            </p>
            {/* Scholarships */}
            <p className="text-lg leading-6 text-gray-500">
              <strong>Scholarships:</strong> Received Sigma Phi Epsilon Balanced
              Man Scholarship (2024); Sigma Alpha Rho Rathblott Scholarship
              (2024); Zeta Beta Tau Freshman of Excellence Scholarship (2024);
              First Time Researcher Fellowship (2024).
            </p>
          </li>

          <li className="text-2xl text-white font-semibold text-center mb-5">
            Extracurriculars
            <p className="text-lg leading-6 text-gray-500">
              <strong>Scalable Asymmetric Life Cycle Engagement (SCALE)</strong>
              , Immersive educational initiative aimed at developing a skilled
              microelectronics workforce for the Department of Defense and
              Defense Industrial Base, currently within the NSWC Crane
              mentorship circle. Authored and curated a monthly newsletter by
              collaborating with professors nationwide to spotlight innovative
              research.
            </p>
            <p className="text-lg leading-6 text-gray-500">
              <strong>Scope Consulting,</strong> Collaborating with Great
              Harvest Bread Company to develop a machine learning model for
              accurate sales and revenue forecasting. Consulted Beta Agency to
              identify commercial real estate opportunities in Southern
              California via a Power BI interface to visualize key storefront
              metrics.
            </p>
          </li>
        </ul>
      </ol>
      {/* <a
        class="mt-5 inline-flex w-full items-center justify-center rounded-md border border-transparent outline-3 outline py-3 text-base font-medium text-white shadow-sm button_slide slide_right "
        download="./assets/Resume/External Resume (engineering) 11_26_24.pdf"
        href="./assets/Resume/External Resume (engineering) 11_26_24.pdf"
      >
        Download Full Resume
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 ml-3"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
          />
        </svg>
      </a> */}
    </div>
  );
}

export default Work;
